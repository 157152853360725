<template>
  <div class="about">
    <p>
      爱答教育专注于年轻人上岸这件事。把最好的教师资格证考试、公务员考试、事业单位考试课程呈现给学员。高效上岸，让每个人都感受到学习带来的蜕变。
    </p>
    <p>
      如果您在使用爱答教育的过程中遇到任何问题，可以通过以下方式联系我们，我们的开发者关系团队会尽快为您解决。
    </p>
    <p>
      QQ: 11357594
    </p>
    <p>
      微信: hepulaocai
    </p>
    <p>
      电话：+86 - 15120094709
    </p>
  </div>
</template>
<script>
export default {};
</script>

<style lang="less" scoped>
p {
  font-size: 1rem;
  margin: 5px 0px;
  color: #323233;
  letter-spacing: 0.44px;
  font-weight: 500;
  margin: 10px 0px;
}
</style>
